<template>
  <van-popup v-model="showPopup" round position="bottom">
    <van-cascader
      v-model="cascaderValue"
      title="请选择户室"
      active-color="#1989fa"
      :options="options"
      :field-names="{ text: 'name', value: 'value' }"
      @close="showPopup = false"
      @change="onChange"
    />
  </van-popup>
</template>
<script>
export default {
  name: 'VillageSelect',
  props: {
    villageId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showPopup: false,
      cascaderValue: '',
      options: []
    }
  },
  methods: {
    show() {
      if (!this.villageId) {
        return
      }
      this.showPopup = true
      this.$http.get(`/village/${this.villageId}/${localStorage.getItem('openId')}`).then(res => {
        if (res.code === 0) {
          this.options = res.data.map(item => {
            return {
              name: item + '幢',
              id: item,
              value: item
            }
          })
        }
      })
    },
    onChange({ selectedOptions, tabIndex }) {
      if (tabIndex == 2) {
        this.showPopup = false
        let obj = {}
        selectedOptions.forEach((item, i) => {
          switch (i) {
            case 0:
              obj.buildingNo = item.id
              obj.buildingName = item.name
              break;
            case 1:
              obj.unitNo = item.id
              obj.unitName = item.name
              break;
            case 2:
              obj.houseNumber = item.id
              obj.houseName = item.name
              break;
            default:
              break;
          }
        })
        this.$emit('input', obj)
        this.$emit('change', obj)
      } else {
        let url = ''
        if (tabIndex == 0) {
          url = `/village/${this.villageId}/${localStorage.getItem('openId')}/${selectedOptions[0].id}`
        } else {
          url = `/village/${this.villageId}/${localStorage.getItem('openId')}/${selectedOptions[0].id}/${selectedOptions[1].id}`
        }
        this.$http.get(url).then(res => {
          if (res.code === 0) {
            let setData = []
            if (tabIndex === 0) {
              setData = res.data.map(item => {
                return {
                  name: item + '单元',
                  id: item,
                  value: selectedOptions[tabIndex].value + 'danyuan' + item,
                }
              })
            } else if (tabIndex === 1) {
              setData = res.data.map(item => {
                return {
                  name: item + '户室',
                  id: item,
                  value: selectedOptions[tabIndex].value + 'hushi' + item,
                }
              })
            }
            console.log(setData)
            this.$set(selectedOptions[tabIndex], 'children', setData)
          }
        })
      }
    }
  }
}
</script>