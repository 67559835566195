import Vue from "vue"
import axios from 'axios'
import { Notify } from 'vant'

const Axios = axios.create({
  baseURL: '/colors-mp',
  timeout: 100000,
  responseType: 'json',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

if (!Vue.prototype.$http) {
  Vue.prototype.$http = Axios
}

export default async () => {
  Vue.prototype.$http.interceptors.request.use(config => { // 请求拦截器
    return config
  }, error => {
    return Promise.reject(error.data.error.message)
  })

  Vue.prototype.$http.interceptors.response.use(res => { // 响应拦截器
    if (res.data.code !== 0) {
      Notify(res.data.msg)
    }
    return res.data
  }, error => {
    if (error.response) {
      Notify(error.response.data.message)
    } else {
      Notify('网络错误，请重试！')
    }
    return Promise.reject(error)
  })
}