<template>
  <div class="home">
    <div class="dot" />
    <div class="dot" style="left: auto; right: 0.4rem;" />
    <div class="inner">
      <div class="code-wrap" ref="qrcode" />
      <h3 class="tc">使用二维码进行垃圾分类</h3>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  name: 'Home',
  data() {
    return {
      qrCode: null
    }
  },
  created() {
    this.$http.get('/bind/wxeb17149aeaf910bb/' + localStorage.getItem('openId')).then(res => {
      if (res.code === 0) {
        if (res.data && res.data.qrCode) {
          this.$nextTick(()=> {
            this.qrCode = new QRCode(this.$refs.qrcode, {
              text: res.data.qrCode,
              width: 220,
              height: 220,
              colorDark: '#000',
              colorLight: '#FFF',
              correctLevel: QRCode.CorrectLevel.M
            })
          })
        } else {
          this.$notify({ type: 'warning', message: '暂未生成二维码，请稍后重试。' })
        }
      } else if (res.code === 10019) {
        this.$router.replace('/bind')
      }
    })
  }
}
</script>
<style lang="less" scoped>
.home {
  position: relative;
  background-color: #00A7FF;
  padding: .7rem;
}
.dot {
  position: absolute;
  width: .5rem;
  height: .5rem;
  border-radius: .5rem;
  background-color: #00A7FF;
  left: 0.4rem;
  top: 5.05rem;
}
.inner {
  height: 15rem;
  background: #FFF url('../assets/bg/qrcode_bg.png') no-repeat center top / 100%;
  border-radius: .2rem;
  overflow: hidden;
  padding-top: 7rem;
  .code-wrap {
    width: 220px;
    height: 220px;
    background-color: #CCC;
    margin: 0 auto;
  }
  h3 {
    font-size: .5rem;
    color: #018cd6;
    line-height: 1.4rem;
  }
}
</style>