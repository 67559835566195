import Vue from 'vue'
import VueRouter from 'vue-router'
import Wrap from './components/wrap'
import Home from './views/Home'
import Bind from './views/Bind'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/bind',
    name: 'Bind',
    component: Bind
  },
  {
    path: '/delivery',
    name: 'Delivery',
    component: Wrap,
    redirect: '/delivery/index',
    children: [
      {
        path: 'index',
        meta: { title: '投放明细' },
        component: () => import('./views/Delivery/index.vue')
      },
      {
        path: 'what',
        meta: { title: '解释' },
        component: () => import('./views/Delivery/what.vue')
      },
      {
        path: 'log',
        meta: { title: '记录详情' },
        component: () => import('./views/Delivery/log.vue')
      }
    ]
  },
  {
    path: '/integral',
    name: 'Integral',
    component: () => import('./views/Integral.vue')
  }
]

const router = new VueRouter({
  mode: 'history', // 配合微信携带appid必要！！！
  routes
})

export default router
