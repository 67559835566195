<template>
  <div class="bind">
    <div class="flex title-wrap">
      <div class="logo-wrap"><img src="@/assets/logo.png"></div>
      <div>
        <h2>绑定户室二维码</h2>
        <h3>手机号绑定</h3>
      </div>
    </div>
    <van-cell-group inset style="margin-bottom: .42rem">
      <van-cell title="选择小区" :value="villageSelect.villageName" is-link @click="showSearch = true" />
      <van-row class="col-cell">
        <van-col :span="8"><van-cell :title="villageSelect.buildingName || '选择楼栋'" is-link @click="$refs.select.show()" /></van-col>
        <van-col :span="8"><van-cell :title="villageSelect.unitName || '选择单元'" is-link @click="$refs.select.show()" /></van-col>
        <van-col :span="8"><van-cell :title="villageSelect.houseName || '选择户室'" is-link @click="$refs.select.show()" /></van-col>
      </van-row>
    </van-cell-group>
    <van-cell-group inset>
      <van-field v-model="name" label="姓名" placeholder="请输入姓名" />
      <van-field v-model="mobile" label="手机号" placeholder="请输入手机号" />
      <!-- <van-field v-model="code" label="验证码" placeholder="请输入验证码">
        <template #button>
          <van-button size="mini" type="info">发送验证码</van-button>
        </template>
      </van-field> -->
    </van-cell-group>
    <div style="padding: .42rem">
      <van-button type="info" round block :loading="binding" :disabled="binding" @click="bind()">确认绑定</van-button>
    </div>
    <div v-if="showSearch" class="search-wrap">
      <van-search v-model="searchKey" placeholder="请输入搜索关键词" @input="onSearch" />
      <van-empty v-if="!searchIng && resultList.length === 0 && searchKey" description="无搜索结果" />
      <div v-else class="result-wrap">
        <van-cell-group>
          <van-cell v-for="item in resultList" :key="item.id" :title="item.villageName" :value="item.streetName" :label="item.communityName" @click="select(item)" />
        </van-cell-group>
      </div>
    </div>
    <HouseSelect ref="select" :villageId="villageSelect.villageId" @input="houseSelect" />
  </div>
</template>
<script>
import HouseSelect from '@/components/HouseSelect'
export default {
  components: { HouseSelect },
  data() {
    return {
      villageSelect: {},
      searchKey: '',
      showSearch: false,
      timer: null,
      resultList: [],
      searchIng: false,
      mobile: '',
      name: '',
      binding: false
    }
  },
  methods: {
    onSearch(name) {
      this.searchIng = true
      clearTimeout(this.timer)
      this.timer = null
      this.timer = setTimeout(() => {
        this.$http.get('/district/wxeb17149aeaf910bb', {
          params: { name }
        }).then(res => {
          this.searchIng = false
          if (res.code === 0) {
            this.resultList = res.data
          }
        }).catch(() => {
          this.searchIng = false
        })
      }, 500)
    },
    select(item) {
      this.showSearch = false
      this.villageSelect = item
    },
    houseSelect(obj) {
      this.villageSelect = { ...this.villageSelect, ...obj }
    },
    bind() {
      this.binding = true
      this.$http.post('/bind/wxeb17149aeaf910bb/' + localStorage.getItem('openId'), { ...this.villageSelect, ...{
        name: this.name,
        mobile: this.mobile
      }}).then(res => {
        this.binding = false
        if (res.code === 0) {
          this.$router.replace('/')
        }
      }).catch(() => {
        this.binding = false
      })
    }
  }
}
</script>
<style lang="less" scoped>
.bind {
  background-color: #EFF7FF;
  padding-top: .42rem;
}
.title-wrap {
  height: 100px;
  margin: 0 16px .42rem;
  border-radius: 8px;
  padding: 0 .6rem;
  justify-content: flex-start;
  background: linear-gradient(43deg, #5EA6FF, #0273FF);
  color: #FFF;
  .logo-wrap {
    background-color: #FFF;
    width: 1.8rem;
    height: 1.8rem;
    padding: 0.3rem;
    border-radius: 1.8rem;
    overflow: hidden;
    margin-right: .4rem;
    img {
      height: 1.2rem;
      width: 1.2rem;
    }
  }
}
.col-cell {
  .van-cell__value {
    display: none;
  }
}
.search-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #F7F8FA;
  .result-wrap {
    height: calc(100vh - 54px);
    overflow: scroll;
  }
}
</style>